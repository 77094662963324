<h2>Conversations</h2>

<div class="action-buttons">
  <button (click)="resetEditingConversationPractice()" nz-button nzType="primary">
    Create new
  </button>
  &nbsp;
  <button (click)="showConversationList()" nz-button>
    Show Created Conversations
  </button>
</div>

<div class="create-panel">
  <ukata-create-conversation
    (onEditConversationPracticeUpdated)="fetchConversationPractices()"
    [editingConversationPractice]="editingConversationPractice"
  ></ukata-create-conversation>
</div>

<ng-template #conversationListTemplate>
  <ukata-conversation-list
    (onConversationPracticeDelete)="fetchConversationPractices()"
    (onEditConversationPracticeChange)="editingConversationPractice=$event"
    [conversationPractices]="conversationPractices"
  ></ukata-conversation-list>
</ng-template>
