import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ConversationPractice } from '../../../../interfaces/conversation-practice';
import { CommonService } from '../../../../../core/services/common.service';
import { MakerCommonService } from '../../../../services/maker-common.service';
import { LoadingService } from '../../../../../core/services/loading.service';
import { NotificationService } from '../../../../../core/notifications/notification.service';
import { ConversationPracticeService } from '../../../../services/conversation-practice.service';
import { MatRadioChange } from '@angular/material/radio';

@Component({
  selector: 'ukata-create-conversation',
  templateUrl: './create-conversation.component.html',
  styleUrls: ['./create-conversation.component.scss'],
})
export class CreateConversationComponent {
  @Input()
  editingConversationPractice: ConversationPractice = {
    description: '',
    language: '',
    level: 0,
    speakers: [],
    status: 'PUBLISHED',
    title: '',
    topic: '',
  };
  @Output()
  onEditConversationPracticeUpdated = new EventEmitter<ConversationPractice>();
  rawConversation: string = '';
  creatingOrUpdatingConversation: boolean = false;
  sourceVoice: string = '';
  targetVoice: string = '';
  protected readonly CommonService = CommonService;
  protected readonly MakerCommonService = MakerCommonService;
  defaultStyle: string = 'default';
  defaultVoice: string = '';

  constructor(
    public loadingService: LoadingService,
    private notificationService: NotificationService,
    private conversationPracticeService: ConversationPracticeService
  ) {}

  createOrUpdate() {
    if (this.editingConversationPractice.id) {
      this.creatingOrUpdatingConversation = true;
      this.conversationPracticeService
        .update(this.editingConversationPractice)
        .subscribe({
          next: (conversationPractice) => {
            this.creatingOrUpdatingConversation = false;
            this.editingConversationPractice = conversationPractice;
            this.onEditConversationPracticeUpdated.next(conversationPractice);
          },
          error: (err) => {
            this.creatingOrUpdatingConversation = false;
            console.log(err);
          },
        });
    } else {
      console.log('create');
      this.createConversationPractice(this.editingConversationPractice);
    }
  }

  copySpeakerId(sp: string) {
    CommonService.copyTextToClipboard(sp);
    this.notificationService.success('copied');
  }

  parseConversation() {
    if (!this.rawConversation) {
      this.notificationService.error('Please input conversation');
      return;
    }
    try {
      this.editingConversationPractice.speakers =
        this.editingConversationPractice.speakers || [];
      const parsed = MakerCommonService.parseConversationPractice(
        this.rawConversation,
        true
      );

      this.editingConversationPractice.speakers.push(...parsed);
    } catch (e) {
      if (e instanceof Error) this.notificationService.error(e.message);
      return;
    }
  }

  flipSpeaker(editingConversationPractice: ConversationPractice) {
    const firstSpeaker = editingConversationPractice.speakers[0].speaker_id;
    const secondSpeaker = editingConversationPractice.speakers[1].speaker_id;
    console.log(firstSpeaker, secondSpeaker);
    editingConversationPractice.speakers =
      editingConversationPractice.speakers.map((sp) => {
        if (sp.speaker_id === firstSpeaker) {
          console.log('flip1', sp.speaker_id, ' to ', secondSpeaker);
          sp.speaker_id = secondSpeaker;
        } else {
          console.log('flip2', sp.speaker_id, ' to ', firstSpeaker);
          sp.speaker_id = firstSpeaker;
        }
        return sp;
      });

    console.log(editingConversationPractice.speakers);
  }

  populateStyle($event: string) {
    this.editingConversationPractice.speakers =
      this.editingConversationPractice.speakers.map((sp) => {
        sp.style = $event;
        return sp;
      });
  }

  changeAllSourceToTargetVoice() {
    if (!this.targetVoice || this.sourceVoice === '') {
      this.notificationService.error('Please select target & source voice');
      return;
    }

    console.log(
      'change all matched source to target voice',
      this.sourceVoice,
      this.targetVoice
    );
    this.editingConversationPractice.speakers =
      this.editingConversationPractice.speakers.map((sp) => {
        if (sp.speaker_id === this.sourceVoice) {
          sp.speaker_id = this.targetVoice;
        }
        return sp;
      });
  }

  changeLayout($event: MatRadioChange) {}

  private createConversationPractice(
    conversationPractice: ConversationPractice
  ) {
    this.creatingOrUpdatingConversation = true;
    this.conversationPracticeService.create(conversationPractice).subscribe({
      next: (conversationPractice) => {
        this.editingConversationPractice = conversationPractice;
        this.creatingOrUpdatingConversation = false;
        this.onEditConversationPracticeUpdated.next(conversationPractice);
      },
      error: (err) => {
        console.log(err);
        this.creatingOrUpdatingConversation = false;
      },
    });
  }
  clearParsed() {
    this.editingConversationPractice.speakers = [];
  }

  applyDefaults() {
    if (!this.defaultStyle || !this.defaultVoice) {
      this.notificationService.error('Please set default voice or style');
      return;
    }
    this.rawConversation = this.rawConversation
      .split('\n')
      .filter((l1) => l1.trim())
      .map((l) => {
        const a1 = l.split('|');
        return (
          this.defaultVoice + '|' + this.defaultStyle + '|' + a1[a1.length - 1]
        );
      })
      .join('\n');
  }
}
